import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";


import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
//import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
import MKAlert from "components/MKAlert";
//import MKPagination from "components/MKPagination";

// Images
import bgImage from "assets/images/examples/blog2.jpg";

//show message
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//connection to backend:
import { updateUserContractSettings } from '../../../../../api/api_contractsettings.js';


function ContractReview() {
  const navigate = useNavigate();
  const location = useLocation();

  // Sample receivedContractData5th structure
  const [receivedContractData5th, setReceivedContractData5th] = useState(location.state.receivedContractData4th);

  useEffect(() => {
    // Log the contents of receivedContractData1st when the component mounts
    console.log(receivedContractData5th);
  }, [receivedContractData5th]);

  const handleInputChange = (e, category, index, field) => {
    setReceivedContractData5th((prevData) => {
      const newData = { ...prevData };
      if (category === "parties") {
        newData[category].partiesData[index][field] = e.target.value;
      } else if (category === "product" || category === "standard" || category === "package") {
        newData[category][field] = e.target.value;
      } else if (category === "steps") {
        newData[category].stepData[index][field] = e.target.value;
      } /* else if (category === "invoice") {
        newData[category][index][field] = e.target.value;
      } */
      return newData;
    });
  };


  const handleNextClick = () => {
    const userEmail = localStorage.getItem('userEmail');
    const updatedFormData = { ...receivedContractData5th, user_email: userEmail };
    // Fetching to API endpoint
    // Use receivedContractData5th instead of formData here
    // Store 2nd and 3rd emails in localStorage for later use
    localStorage.setItem('2ndWallet', updatedFormData.parties.partiesData[1].walletAddress);
    localStorage.setItem('3rdWallet', updatedFormData.parties.partiesData[2].walletAddress);
    updateUserContractSettings(updatedFormData)
      .then((updatedFormData) => {
        // Handle successful update
        // You can set the updated data in the state or take other actions
        // For instance, if your API returns updated user data:
        // setUser(receivedContractData5th);
        toast.success("Your settings stored successfully!");
        console.log("formData", updatedFormData); // Use updatedFormData here as well
        const contId = localStorage.getItem('lastInsertId');
        window.location.href = `/dashboard/contract-initiate?id=${contId}`;
      })
      .catch((error) => {
        // Handle errors
        toast.error("Failed to store your settings. Please try again.");
        console.error('Error updating CoCampaign settings:', error);
        // Show an error message or take necessary steps
      });
  };
  return (
    <>
      <MKBox bgColor="white" >
        <ToastContainer /> {/* Add this component to your component */}
        <MKBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }} >
          <Card
            sx={{
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              float: "center",
              maxWidth: "70rem", // Set the desired width value
              //margin: "auto",   // Center the card horizontally
            }}
          >
            <Container >
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <Grid container spacing={3} justifyContent="center" py={12}>
                  <MKTypography variant="h4" color="blac" mb={1}>
                    Check your contract settings and modify if needed
                  </MKTypography>
                  <Grid item xs={12} pr={1} mb={6}>
                    <MKAlert color="info">1. Contract&apos;s Parties</MKAlert>
                    <Grid container spacing={2}>
                      {/* Rendering and editing parties data */}
                      {receivedContractData5th.parties.partiesData.slice(0, receivedContractData5th.parties.numberOfParties).map((party, partyIndex) => (
                        <Grid item xs={12} key={partyIndex}>
                          <TextField
                            label={`Party ${partyIndex + 1} Name`}
                            value={party.name}
                            onChange={(e) => handleInputChange(e, "parties", partyIndex, "name")}
                          />
                          <TextField
                            label={`Party ${partyIndex + 1} Address`}
                            value={party.address}
                            onChange={(e) => handleInputChange(e, "parties", partyIndex, "address")}
                          />
                          <TextField
                            label={`Party ${partyIndex + 1} Phone Number`}
                            value={party.phoneNumber}
                            onChange={(e) => handleInputChange(e, "parties", partyIndex, "phoneNumber")}
                          />
                          <TextField
                            label={`Party ${partyIndex + 1} Zip Code`}
                            value={party.zipCode}
                            onChange={(e) => handleInputChange(e, "parties", partyIndex, "zipCode")}
                          />
                          <TextField
                            label={`Party ${partyIndex + 1} Waller Address`}
                            value={party.walletAddress}
                            onChange={(e) => handleInputChange(e, "parties", partyIndex, "walletAddress")}
                          />
                          {/* Render other party details (address, phoneNumber, zipCode, walletAddress) similarly */}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item xs={12} pr={1} mb={6} style={{ marginTop: "2em" }}>
                      <MKAlert color="info">2. Contract&apos;s Product(s)</MKAlert>
                      {/* Rendering and editing product data */}
                      <Grid item xs={12}>
                        <MKInput
                          label="Product Name"
                          value={receivedContractData5th.product.name}
                          onChange={(e) => handleInputChange(e, "product", null, "name")}
                        />
                        <MKInput
                          label="Product HS Code"
                          value={receivedContractData5th.product.hsCode}
                          onChange={(e) => handleInputChange(e, "product", null, "hsCode")}
                        />
                        {/* Render other product details (hsCode, amount, packageType, packageAmount, invoice) similarly */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} pr={1} mb={6}>
                      <MKAlert color="info">3. Contract&apos;s Package(s)</MKAlert>
                      {/* Rendering and editing package data */}
                      <Grid item xs={12}>
                        <TextField
                          label="Package Type"
                          value={receivedContractData5th.package.type}
                          onChange={(e) => handleInputChange(e, "package", null, "type")}
                        />
                        <TextField
                          label="Amount of Packs"
                          value={receivedContractData5th.package.amount}
                          onChange={(e) => handleInputChange(e, "package", null, "amount")}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} pr={1} mb={6}>
                      <MKAlert color="info">4. Contract&apos;s Standard(s)</MKAlert>
                      {/* Rendering and editing standard data */}
                      <Grid item xs={12}>
                        <TextField
                          label="Is it Required?"
                          value={receivedContractData5th.standard.required}
                          onChange={(e) => handleInputChange(e, "standard", null, "required")}
                        />
                        <TextField
                          label="Standards"
                          value={receivedContractData5th.standard.list}
                          onChange={(e) => handleInputChange(e, "standard", null, "list")}
                        />
                        {/* Render other standard details (list) similarly */}
                      </Grid>

                    </Grid>
                    {/*}
                    <Grid item xs={12} pr={1} mb={6}>
                      <MKAlert color="info">5. Contract&apos;s Invoice</MKAlert>
                      {/* Rendering and editing invoice data */} {/*}
                      {receivedContractData5th.invoice.map((item, invoiceIndex) => (
                        <Grid item xs={12} key={invoiceIndex}>
                          <TextField
                            label={`Item ${invoiceIndex + 1} Description`}
                            value={item.description}
                            onChange={(e) => handleInputChange(e, "invoice", invoiceIndex, "description")}
                          />
                          <TextField
                            label={`Item ${invoiceIndex + 1} Discount`}
                            value={item.discount}
                            onChange={(e) => handleInputChange(e, "invoice", invoiceIndex, "discount")}
                          />
                          <TextField
                            label={`Item ${invoiceIndex + 1} Quantity`}
                            value={item.quantity}
                            onChange={(e) => handleInputChange(e, "invoice", invoiceIndex, "quantity")}
                          />
                          <TextField
                            label={`Item ${invoiceIndex + 1} Unit Price`}
                            value={item.unitPrice}
                            onChange={(e) => handleInputChange(e, "invoice", invoiceIndex, "unitPrice")}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {*/}
                    <Grid item xs={12} pr={1} mb={6}>
                      <MKAlert color="info">6. Contract&apos;s Step(s)</MKAlert>
                      {/* Rendering and editing steps data */}
                      {receivedContractData5th.steps.stepData.map((step, stepIndex) => (
                        <Grid item xs={12} key={stepIndex}>
                          <TextField
                            label={`Step ${stepIndex + 1} Title`}
                            value={step.title}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "title")}
                          />
                          <TextField
                            label={`Step ${stepIndex + 1} Cost`}
                            value={step.amount}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "amount")}
                          />
                          <TextField
                            label={`Step ${stepIndex + 1} Payer`}
                            value={step.payer}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "payer")}
                          />
                          <TextField
                            label={`Step ${stepIndex + 1} Money Reciever`}
                            value={step.receiver}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "receiver")}
                          />
                          <TextField
                            label={`Step ${stepIndex + 1} Settlement Time`}
                            value={step.settlementtime}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "settlementtime")}
                          />
                          {/*
                          <TextField
                            label={`Step ${stepIndex + 1} Penalty For Each Day Of Delay`}
                            value={step.PenaltyForEachDayOfDelay}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "PenaltyForEachDayOfDelay")}
                          />
                          <TextField
                            label={`Step ${stepIndex + 1} Penalty Receiver`}
                            value={step.penaltyReceiver}
                            onChange={(e) => handleInputChange(e, "steps", stepIndex, "penaltyReceiver")}
                          />
                          /*}
                          {/* Render other step details (amount, payer, receiver, settlementtime, confirmationMethod, PenaltyForEachDayOfDelay, penaltyReceiver) similarly */}
                        </Grid>
                      ))}
                    </Grid>
                    {/* Next button */}
                    <Grid item xs={12}>
                      <MKButton variant="gradient" color="info" onClick={handleNextClick}>
                        Next
                      </MKButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </MKBox>
      </MKBox >
    </>
  );
}

export default ContractReview;
