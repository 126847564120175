import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
//import MKPagination from "components/MKPagination";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import ContractCosts from "./ContractCosts";


function ContractCondition() {
  const navigate = useNavigate();
  const location = useLocation();


  //const [receivedContractData4th, setReceivedContractData4th] = useState(location.state.receivedContractData3rd);
  const [receivedContractData4th, setReceivedContractData4th] = useState(
    location.state.receivedContractData3rd && location.state.receivedContractData3rd.parties.numberOfParties === 3
      ? location.state.receivedContractData3rd
      : location.state.receivedContractData2nd
  );


  useEffect(() => {
    // Log the contents of receivedContractData1st when the component mounts
    console.log(receivedContractData4th);
  }, [receivedContractData4th]);

  const updateContractDataAfterContractCondition = () => {

    const updatedData = receivedContractData4th;


    // Log the updated data
    console.log(updatedData);

    // Navigate to the next page and pass contractData as a prop
    navigate("/services/smart-contract/contract-review", {
      state: { receivedContractData4th: updatedData },
    });
  };

  // Function to handle navigation to the next page
  const handleNextClick = () => {
    // Ensure contractData is updated before navigating
    updateContractDataAfterContractCondition();
  };
  const handleGoBack = () => {
    navigate(-1); // Go back one step in the history stack
  };
  return (
    <MKBox component="section" py={{ xs: 0, lg: 8 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Set Smart Contract Type-A
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Craft your customized Type-A smart contract effortlessly. Define parties,
                      cargo specifics, and confirmation protocols to streamline your secure
                      international trade agreements.
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      3/8. The Journey
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Define the steps of the journey in order, define the cost, the executor and
                      the payer, and define the non-performance penalty structure.
                    </MKTypography>
                    <Grid
                      container
                      item
                      xs={0}
                      md={0}
                      justifyContent="center"
                      textAlign="right"
                      ml="auto"
                    >
                      <ContractCosts receivedContractData4th={receivedContractData4th} setReceivedContractData4th={setReceivedContractData4th} />
                    </Grid>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={10}
                        md={6}
                        justifyContent="flex-start"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="outlined" color="info" onClick={handleGoBack}>
                          Back
                        </MKButton>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="info" onClick={handleNextClick}>
                          Next
                        </MKButton>
                      </Grid>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

ContractCondition.propTypes = {
  receivedContractData4th: PropTypes.object,
  setReceivedContractData4th: PropTypes.func,
};

export default ContractCondition;

