/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import {
  fetchContracts,
} from '../../../../../api/dashboardApi';


function Information() {

  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const loadContracts = async () => {
      try {
        const contractsData = await fetchContracts();
        setContracts(contractsData);
      } catch (error) {
        console.error('Error loading contracts:', error);
      }
    };

    loadContracts();
  }, []);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid container spacing={3}>
                {contracts.map((contract) => (
                  <Grid item key={contract.id} xs={12} md={4}>
                    <MKBox mb={5}>
                      <Link
                        to={`/dashboard/contract-initiate?id=${contract.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <DefaultInfoCard
                          icon="apps"
                          title={`Contract with: ${contract.party2_name} ...`}
                        />
                      </Link>
                    </MKBox>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
