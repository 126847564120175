// api/api_contractsettings.js

//---------------------1 changes for deploy
import { API_URL } from './constants';

export const updateUserContractSettings = async (formData) => {
  try {
    const formDataSend = { formData }
    const userToken = localStorage.getItem('userToken');
    const response = await fetch(`${API_URL}/contract-settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(formDataSend),
    });

    if (response.ok) {
      // Process successful response
      const { result, lastInsertId } = await response.json();
      console.log('Contract settings stored successfully:', result, lastInsertId);

      // Store lastInsertId in localStorage for later use
      localStorage.setItem('lastInsertId', lastInsertId);

    } else {
      // Handle error response
      console.error('Error storing Contract settings:', response.statusText);
    }
  } catch (error) {
    console.error('Error storing Contract settings from front api_Contractsettings:', error.message);
  }
};
