import Footer from "./sections/Footer";
import PropTypes from "prop-types";

// Import the necessary components here
import MKBox from "components/MKBox";
import ThirdPartyInfo from "./sections/Contact";

// Routes
//import routes from "routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/city-profile.jpg";

function Set3rdParty({ receivedContractData3rd }) {
  return (
    <>
      <MKBox bgColor="white">
        <ThirdPartyInfo receivedContractData3rd={receivedContractData3rd} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>      
      </MKBox>
    </>
  );
}

Set3rdParty.propTypes = {
  receivedContractData3rd: PropTypes.object,
  //setContractData: PropTypes.func.isRequired,
};

export default Set3rdParty;
