/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useReducer } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";

//Footer
//import DefaultFooter from "examples/Footers/DefaultFooter";
//import footerRoutes from "footer.routes";

// Routes
//import routes from "NavRoutes";

// Images
//import bgImage from "assets/images/find-partner.jpg";

//inputs
import MKInput from "components/MKInput";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Divider from "@mui/material/Divider";


// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// @mui material components
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

//Icons of processes
//import { AiFillCheckCircle, AiOutlineSearch, AiOutlineFileSearch, AiTwotoneEdit, AiOutlineMail, AiFillDatabase } from 'react-icons/ai';


import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Progress,
  Badge,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationLink
} from "reactstrap";


//import { Flex, Text } from '@chakra-ui/react';

//import { IoCheckmarkDoneCircleSharp, IoEllipsisHorizontal } from 'react-icons/io5';
//import { AiFillCheckCircle, AiOutlineSearch, AiOutlineFileSearch, AiTwotoneEdit, AiOutlineMail, AiFillDatabase } from 'react-icons/ai';


//import MessageInbox from "components/MessageInbox";
import { toast } from "react-toastify";

import MKAlert from "components/MKAlert";

//connection to backend

import {
  initializeCoCampaignResults,
  updateDepositStatus,
  updateCoCampaignResults,
  updateCoCampaignResultsAction,
} from '../../../../api/contractParty2Api';

// Connection to Blockchain
import Web3 from "web3";
import { SimplePaymentContract } from '../../../../abi/abi.js';
import { Typography } from "@mui/material";


const num_links_saved = 1;
const num_emails_stored = 1;
const num_send_email = 1;
const email_text_fifth = "hi";
/*
party1_acceptance_status
party1_deposit_value,
party1_deposit_status,
party2_acceptance_status,
party2_deposit_value,
party2_deposit_status,
party3_acceptance_status,
party3_deposit_value,
party3_deposit_status,
steps_payments_json
*/
function DashboardPage() {

  const [user_email, dispatchUserEmail] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_USER_EMAIL':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [number_of_parties, dispatchNumberOfParties] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_NUMBER_OF_PARTIES':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 2);


  const [party1_name, dispatchParty1Name] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_name, dispatchParty2Name] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [party3_name, dispatchParty3Name] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);




  const [party1_acceptance_status, dispatchParty1AcceptanceStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_ACCEPTANCE_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_acceptance_status, dispatchParty2AcceptanceStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_ACCEPTANCE_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party3_acceptance_status, dispatchParty3AcceptanceStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_ACCEPTANCE_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [party1_deposit_value, dispatchParty1DepositValue] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_VALUE':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_deposit_value, dispatchParty2DepositValue] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_VALUE':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party3_deposit_value, dispatchParty3DepositValue] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_DEPOSIT_VALUE':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [party1_deposit_status, dispatchParty1DepositStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_deposit_status, dispatchParty2DepositStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party3_deposit_status, dispatchParty3DepositStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_DEPOSIT_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [steps_payments_json, dispatchStepsPaymentsJson] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_STEPS_PAYMENTS_JSON':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  });

  //////New for part2
  const [product_name, dispatchProductName] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PRODUCT_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [product_hsCode, dispatchProducthsCode] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PRODUCT_HSCODE':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [package_type, dispatchPackageType] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PACKAGE_TYPE':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [package_amount, dispatchPackageAmount] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PACKAGE_AMOUNT':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [standard_required, dispatchStandardRequired] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_STANDARD_REQUIRED':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [standard_list, dispatchStandardList] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_STANDARD_LIST':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [steps_data, dispatchStepsData] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_STEPS_DATA':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  ////////

  useEffect(() => {
    const contractId = localStorage.getItem('contractId');
    console.log('in useEffect,contractId:', contractId)
    if (contractId) {
      // Pass 'dispatchNumEmailsStored' and 'dispatchNumSendEmail' to the 'initializeCoCampaignResults' function
      initializeCoCampaignResults(contractId, dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name,
        dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
        dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
        dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson,
        dispatchProductName, dispatchProducthsCode, dispatchPackageType, dispatchPackageAmount,
        dispatchStandardRequired, dispatchStandardList, dispatchStepsData);
    }
  }, [dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name,
    dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
    dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
    dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson,
    dispatchProductName, dispatchProducthsCode, dispatchPackageType, dispatchPackageAmount,
    dispatchStandardRequired, dispatchStandardList, dispatchStepsData]);



  //Link Generation:
  // Assuming lastInsertId and emailAddress are available
  // Constructing the URL with query parameters





  //smart contract connection
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [account, setAccount] = useState("");
  const [contractBalance, setContractBalance] = useState(0);

  const contractAddress = '0x2A6c5a6A490dAb95CC808192352e21ba98e6e71A';
  const rpcEndpoint = 'https://sepolia.infura.io/v3/b61958b591624a369c6f986a1ccc09f8'; // Infura RPC endpoint for Sepolia

  useEffect(() => {
    const initialize = async () => {
      if (window.ethereum) {
        try {
          //const web3Instance = new Web3(rpcEndpoint); // Instantiate Web3 with custom RPC endpoint
          const web3Instance = new Web3(window.ethereum); //test code
          await window.ethereum.enable();
          setWeb3(web3Instance);
          console.log('inside initialize, web3Instance:', web3Instance);
          const accounts = await web3Instance.eth.getAccounts();
          console.log('inside initialize, accounts:', accounts);
          if (accounts.length > 0) {
            console.log('inside initialize, accounts[0]:', accounts[0]);
            setAccount(accounts[0]);
            const contractInstance = new web3Instance.eth.Contract(
              SimplePaymentContract,
              contractAddress
            );
            console.log('inside initialize, contractInstance:', contractInstance);
            setContract(contractInstance);
          } else {
            console.error('No accounts found.');
          }
        } catch (error) {
          console.error('Error initializing web3:', error);
        }
      } else {
        console.error('MetaMask not detected.');
      }
    };
    initialize();
  }, []);


  useEffect(() => {
    const fetchContractBalance = async () => {
      if (contract) {
        try {
          const balance = await contract.methods.contractBalance().call();
          setContractBalance(balance);
        } catch (error) {
          console.error("Error fetching contract balance:", error);
        }
      }
    };
    fetchContractBalance();
  }, [contract]);

  const deposit = async () => {
    //await initialize();
    console.log('inside deposit, contract', contract)
    if (contract) {
      try {
        await contract.methods.sendPayment().send({
          from: account,
          value: web3.utils.toWei(parseFloat(party2_deposit_value).toString(), "ether"),
        });
        // Update the party3_deposit_status to 'Paid' locally
        dispatchParty2DepositStatus({ type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_STATUS', payload: 'Paid' });
        // Send a request to update the party3_deposit_status in the backend
        const contId = localStorage.getItem('contractId');
        const depstatus = 'Paid';
        updateDepositStatus(contId, depstatus); // Function to send a request to update the deposit status in the backend

        console.log("Deposit successful!");
      } catch (error) {
        console.error("Deposit failed:", error);
      }
    }
  };

  const withdraw = async (price) => {
    if (contract) {
      try {
        await contract.methods.withdraw(
          web3.utils.toWei(price.toString(), "ether")
        ).send({ from: account });
        console.log("Withdrawal successful!");
      } catch (error) {
        console.error("Withdrawal failed:", error);
      }
    }
  };

  const getContractBalance = async () => {
    if (contract) {
      try {
        const balance = await contract.methods.contractBalance().call();
        setContractBalance(balance);
      } catch (error) {
        console.error("Error fetching contract balance:", error);
      }
    }
  };

  const [verificationCode, setVerificationCode] = useState('');
  //console.log("entered verificationCode", verificationCode)

  // Function to handle verification code change
  const handleVerificationCodeChange = (e) => {
    const code = e.target.value;
    setVerificationCode(code);

    // Find the step matching the verification code
    const matchingStep = JSON.parse(steps_payments_json).find((step) => {
      return code == step.step_verification_code;
    });

    // If a matching step is found, update withdrawAmount
    if (matchingStep) {
      console.log('typeof(matchingStep.amount)', typeof (matchingStep.amount));
      const floatAmount = parseFloat(matchingStep.amount); // Convert amount to float
      withdraw(floatAmount);
      console.log('typeof(x)', typeof (x));
      //withdraw(floatAmount)
      setWithdrawAmount(floatAmount);
      console.log("withdrawAmount", withdrawAmount);
      //console.log("withdrawAmount", withdrawAmount);
    } else {
      // Handle case where no matching step is found
      setWithdrawAmount(0); // Set withdrawAmount to default value
      console.log("No matching step found");
    }
  };



  return (
    <>
      <MKBox bgColor="white" >
        <MKBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }} >

          <Card
            sx={{
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              float: "center",
              maxWidth: "70rem", // Set the desired width value
              //margin: "auto",   // Center the card horizontally
            }}
          >
            <Container >
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <Grid container spacing={3} justifyContent="center" py={12}>
                  <MKAlert color="light" sx={{ width: '90%', justifyContent: 'center' }}>
                    <MKTypography variant="h3" color="blac" mb={1}>
                      Contract Initiating & Progress
                    </MKTypography>
                  </MKAlert>

                  <MKBox component="section" py={{ xs: 0, md: 2 }}>
                    <Container>
                      <Grid container>
                        <Grid item xs={12} lg={6}>
                          <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
                            <h3>Check Contract Specs</h3>
                            <MKAlert color="light" sx={{ width: '100%' }}>
                              Product Name: {product_name} <br />
                              Product hsCode: {product_hsCode} <br />
                              Package Type: {package_type} <br />
                              Package Amount: {package_amount} <br />
                              Standard Required: {standard_required} <br />
                              Standard List: {standard_list} <br />
                            </MKAlert>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                          <Stack>
                            <h3>Check Contract Steps</h3>
                            <MKAlert color="light" sx={{ width: '100%' }}>
                              Party1 Deposit Value: {party1_deposit_value} ETH <br />
                              Party2 Deposit Value: {party2_deposit_value} ETH <br />
                              Party3 Deposit Value: {party3_deposit_value} ETH <br />

                            </MKAlert>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Container>
                  </MKBox>
                  <Grid item xs={12} pr={1} mb={5}>
                    <div>
                      <h3>Deposit your obligations</h3>
                      <MKAlert color="light" sx={{ width: '100%' }}>
                        To have permission to start the contract, you must pay your obligations. <br />
                        The total amount of your obligations is equal to: {party2_deposit_value} ETH <br />
                      </MKAlert>
                      <MKButton onClick={deposit} color="error">Deposit {party2_deposit_value} ETH </MKButton>
                    </div>
                    <h3>Contract Steps</h3>
                    {party2_deposit_status == "Paid" ? (
                    <div>
                      {steps_payments_json ? (
                        JSON.parse(steps_payments_json).map((step, index) => (<MKAlert key={index}>
                          <div>Step {index + 1}: {step.title}</div>
                          <div>Payer: {step.payer_email === party1_name ? 'party-2' : (step.payer_email === party2_name ? 'party-2' : 'party-3')}</div>
                          <div>Receiver: {step.receiver_email === party1_name ? 'party-2' : (step.receiver_email === party2_name ? 'party-2' : 'party-3')}</div>
                          <div>
                            {step.payer_email === party2_name && (
                              <div>
                                You are payer in this step. When you confirm finishing of this step, send the secret verification code to your receiver party.
                                <br /><br />
                                The step Secret Verification Code: {step.step_verification_code}
                              </div>
                            )}
                            {step.receiver_email === party2_name && (
                              <div>
                                You are receiver in this step. Once the paying party has verified your work and sent you the secret verification code, enter it below:
                                <br /><br />
                                Enter the Secret Verification Code:
                                <input
                                  type="number"
                                  value={verificationCode}
                                  onChange={handleVerificationCodeChange}
                                />
                              </div>
                            )}
                          </div>
                        </MKAlert>
                        ))
                      ) : (
                        <div>Loading steps...</div>
                      )}
                    </div>
                    ) : (
                      <Grid item xs={12} pr={1} mb={5}>
                        <p style={{ color: "red" }}>After paying deposit, steps will be shown here!</p>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </MKBox >

      </MKBox >
    </>
  );
}

export default DashboardPage;
