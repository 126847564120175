/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useReducer } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

//inputs
import MKInput from "components/MKInput";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Divider from "@mui/material/Divider";


// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// @mui material components
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";


import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Progress,
  Badge,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationLink
} from "reactstrap";


//import MessageInbox from "components/MessageInbox";
import { toast } from "react-toastify";

import MKAlert from "components/MKAlert";

//connection to backend

import {
  initializeCoCampaignResults,
  updateDepositStatus,
  updateCoCampaignResults,
  updateCoCampaignResultsAction,
} from '../../../../api/contractResultsApi';

// Connection to Blockchain
import Web3 from "web3";
import { SimplePaymentContract } from '../../../../abi/abi.js';
import { Typography } from "@mui/material";

/*
party1_acceptance_status
party1_deposit_value,
party1_deposit_status,
party2_acceptance_status,
party2_deposit_value,
party2_deposit_status,
party3_acceptance_status,
party3_deposit_value,
party3_deposit_status,
steps_payments_json
*/
function DashboardPage() {

  const [user_email, dispatchUserEmail] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_USER_EMAIL':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [number_of_parties, dispatchNumberOfParties] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_NUMBER_OF_PARTIES':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 2);


  const [party1_name, dispatchParty1Name] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_name, dispatchParty2Name] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [party3_name, dispatchParty3Name] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_NAME':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);




  const [party1_acceptance_status, dispatchParty1AcceptanceStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_ACCEPTANCE_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_acceptance_status, dispatchParty2AcceptanceStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_ACCEPTANCE_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party3_acceptance_status, dispatchParty3AcceptanceStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_ACCEPTANCE_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [party1_deposit_value, dispatchParty1DepositValue] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_VALUE':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_deposit_value, dispatchParty2DepositValue] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_VALUE':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party3_deposit_value, dispatchParty3DepositValue] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_DEPOSIT_VALUE':
        return action.payload || 0;
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [party1_deposit_status, dispatchParty1DepositStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party2_deposit_status, dispatchParty2DepositStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);
  const [party3_deposit_status, dispatchParty3DepositStatus] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_DEPOSIT_STATUS':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  }, 0);

  const [steps_payments_json, dispatchStepsPaymentsJson] = useReducer((state, action) => {
    // Handle state updates based on the action type
    switch (action.type) {
      case 'UPDATE_CO_CAMPAIGN_RESULTS_STEPS_PAYMENTS_JSON':
        return action.payload || '';
      // Add more cases if needed
      default:
        return state;
    }
  });

  // Assuming lastInsertId and emailAddress are available
  //const contId = localStorage.getItem('lastInsertId'); // Replace with the actual value
  const contId = new URLSearchParams(window.location.search).get('id');

  useEffect(() => {
    /*const userEmail = localStorage.getItem('userEmail');*/
    if (contId) {
      // Pass 'dispatchNumEmailsStored' and 'dispatchNumSendEmail' to the 'initializeCoCampaignResults' function
      initializeCoCampaignResults(contId, dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name,
        dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
        dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
        dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson);
    }
  }, [dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name,
    dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
    dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
    dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson]);



  //Link Generation:


  // Constructing the URL with query parameters
  const secondContractUrl = `https://smcontract.info/party2-login?contId=${contId}`;
  const thirdContractUrl = `https://smcontract.info/party3-login?contId=${contId}`;

  console.log("Generated URL:", secondContractUrl);





  //smart contract connection
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [account, setAccount] = useState("");
  const [contractBalance, setContractBalance] = useState(0);

  const contractAddress = '0x2A6c5a6A490dAb95CC808192352e21ba98e6e71A';
  const rpcEndpoint = 'https://sepolia.infura.io/v3/b61958b591624a369c6f986a1ccc09f8'; // Infura RPC endpoint for Sepolia

  useEffect(() => {
    const initialize = async () => {
      if (window.ethereum) {
        try {
          //const web3Instance = new Web3(rpcEndpoint); // Instantiate Web3 with custom RPC endpoint
          const web3Instance = new Web3(window.ethereum); //test code
          await window.ethereum.enable();
          setWeb3(web3Instance);
          console.log('inside initialize, web3Instance:', web3Instance);
          const accounts = await web3Instance.eth.getAccounts();
          console.log('inside initialize, accounts:', accounts);
          if (accounts.length > 0) {
            console.log('inside initialize, accounts[0]:', accounts[0]);
            setAccount(accounts[0]);
            const contractInstance = new web3Instance.eth.Contract(
              SimplePaymentContract,
              contractAddress
            );
            console.log('inside initialize, contractInstance:', contractInstance);
            setContract(contractInstance);
          } else {
            console.error('No accounts found.');
          }
        } catch (error) {
          console.error('Error initializing web3:', error);
        }
      } else {
        console.error('MetaMask not detected.');
      }
    };
    initialize();
  }, []);


  useEffect(() => {
    const fetchContractBalance = async () => {
      if (contract) {
        try {
          const balance = await contract.methods.contractBalance().call();
          setContractBalance(balance);
        } catch (error) {
          console.error("Error fetching contract balance:", error);
        }
      }
    };
    fetchContractBalance();
  }, [contract]);
  const deposit = async () => {
    //await initialize();
    console.log('inside deposit, contract', contract)
    if (contract) {
      try {
        await contract.methods.sendPayment().send({
          from: account,
          value: web3.utils.toWei(parseFloat(party1_deposit_value).toString(), "ether"),
        });
        // Update the party1_deposit_status to 'Paid' locally
        dispatchParty1DepositStatus({ type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_STATUS', payload: 'Paid' });
        // Send a request to update the party1_deposit_status in the backend
        const depstatus = 'Paid';
        updateDepositStatus(contId, depstatus); // Function to send a request to update the deposit status in the backend
        console.log("Deposit successful!");
      } catch (error) {
        console.error("Deposit failed:", error);
      }
    }
  };

  const withdraw = async (price) => {
    if (contract) {
      try {
        await contract.methods.withdraw(
          web3.utils.toWei(price.toString(), "ether")
        ).send({ from: account });
        console.log("Withdrawal successful!");
      } catch (error) {
        console.error("Withdrawal failed:", error);
      }
    }
  };

  const getContractBalance = async () => {
    if (contract) {
      try {
        const balance = await contract.methods.contractBalance().call();
        setContractBalance(balance);
      } catch (error) {
        console.error("Error fetching contract balance:", error);
      }
    }
  };

  const [verificationCode, setVerificationCode] = useState('');
  //console.log("entered verificationCode", verificationCode)

  // Function to handle verification code change
  const handleVerificationCodeChange = (e) => {
    const code = e.target.value;
    setVerificationCode(code);

    // Find the step matching the verification code
    const matchingStep = JSON.parse(steps_payments_json).find((step) => {
      return code == step.step_verification_code;
    });

    // If a matching step is found, update withdrawAmount
    if (matchingStep) {
      console.log('typeof(matchingStep.amount)', typeof (matchingStep.amount));
      const floatAmount = parseFloat(matchingStep.amount); // Convert amount to float
      withdraw(floatAmount);
      console.log('typeof(x)', typeof (x));
      //withdraw(floatAmount)
      setWithdrawAmount(floatAmount);
      console.log("withdrawAmount", withdrawAmount);
      //console.log("withdrawAmount", withdrawAmount);
    } else {
      // Handle case where no matching step is found
      setWithdrawAmount(0); // Set withdrawAmount to default value
      console.log("No matching step found");
    }
  };



  return (
    <>
      <MKBox bgColor="white" >
        <MKBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }} >

          <Card
            sx={{
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              float: "center",
              maxWidth: "70rem", // Set the desired width value
              //margin: "auto",   // Center the card horizontally
            }}
          >
            <Container >
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <Grid container spacing={3} justifyContent="center" py={12}>
                  <MKAlert color="light" sx={{ width: '100%', justifyContent: 'center' }}>
                    <MKTypography variant="h3" color="blac" mb={1}>
                      Contract Initiating & Progress
                    </MKTypography>
                  </MKAlert>

                  <MKBox component="section" py={{ xs: 0, md: 2 }}>
                    <Container>
                      <Grid container>
                        <Grid item xs={12} lg={6}>
                          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
                            <div>
                              <h3>Share with your Party(s)</h3>
                              <MKAlert color="light" sx={{ width: '100%' }}>
                                Party-2&apos; Contract URL: <br />
                                {secondContractUrl}
                                <br />Party-2 must be registered on the website before using the link.
                              </MKAlert>
                              {number_of_parties == 3 && (
                                <MKAlert color="light" sx={{ width: '100%' }}>
                                  Party-3&apos; Contract URL: <br />
                                  {thirdContractUrl}
                                  <br />Party-3 must be registered on the website before using the link.
                                </MKAlert>
                              )}
                            </div>
                            {/*}
                                <input
                                  type="number"
                                  value={depositAmount}
                                  onChange={(e) => setDepositAmount(e.target.value)}
                                />
                                <button onClick={deposit}>Deposit</button>
                              </div>
                              <div>
                                <h2>Withdraw</h2>
                                <input
                                  type="number"
                                  value={withdrawAmount}
                                  onChange={(e) => setWithdrawAmount(e.target.value)}
                                />
                                <button onClick={withdraw}>Withdraw</button>
                              </div>
                              <div>
                                <h2>Contract Balance</h2>
                                <p>Balance: {web3 && web3.utils.fromWei(contractBalance.toString(), "ether")} ETH</p>
                                <button onClick={getContractBalance}>Get Contract Balance</button>
                              </div>
                            </div>
                            <MKAlert color="error" sx={{ width: '100%' }}>
                              Get charged 15 euros for every positive response. <br />
                              Ensure a balance of 30 euros to send 500 new messages; messaging is free otherwise!
                            </MKAlert>
                            {/*}
                            <MKAlert color="light" sx={{ width: '100%' }}>
                              {secondContractUrl}
                            </MKAlert>
                            <MKAlert color="light" sx={{ width: '100%' }}>
                              My account: {account}
                            </MKAlert>
                            <button onClick={handleMakePayment}>Make Payment</button>
                            <button onClick={handleReceivePayment}>Receive Payment</button>
                            <button onClick={handleSubmit}>New Receive Payment</button>
                                                       {*/}

                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                          <Stack>
                            {/*}
                            <div>
                              <h3>Status of your Party(s)</h3>
                              <MKAlert color="light" sx={{ width: '100%' }}>
                                Party-2: <br />
                                Status of Acceptance: {party2_acceptance_status} <br />
                                Status of Deposit Payment: {party2_deposit_status}
                              </MKAlert>
                              <MKAlert color="light" sx={{ width: '100%' }}>
                                Party-3: <br />
                                Status of Acceptance: {party3_acceptance_status} <br />
                                Status of Deposit Payment: {party3_deposit_status}
                              </MKAlert>
                            </div>
                                                      {*/}
                            <div>
                              <h3>Deposit your obligations</h3>
                              <MKAlert color="light" sx={{ width: '100%' }}>
                                To have permission to start the contract, you must pay your obligations. <br />
                                The total amount of your obligations is equal to: {party1_deposit_value} ETH <br />
                              </MKAlert>
                              <MKButton onClick={deposit} color="error">Deposit {party1_deposit_value} ETH </MKButton>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Container>
                  </MKBox>
                  {party1_deposit_status == "Paid" ? (
                    <Grid item xs={12} pr={1} mb={5}>
                      <h3>Contract Steps</h3>
                      <div>
                        {steps_payments_json ? (
                          JSON.parse(steps_payments_json).map((step, index) => (<MKAlert key={index}>
                            <div>Step {index + 1}: {step.title}</div>
                            <div>Payer: {step.payer_email === party1_name ? 'party-1' : (step.payer_email === party2_name ? 'party-2' : 'party-3')}</div>
                            <div>Receiver: {step.receiver_email === party1_name ? 'party-1' : (step.receiver_email === party2_name ? 'party-2' : 'party-3')}</div>
                            <div>
                              {step.payer_email === party1_name && (
                                <div>
                                  You are payer in this step. When you confirm finishing of this step, send the secret verification code to your receiver party.
                                  <br /><br />
                                  The step Secret Verification Code: {step.step_verification_code}
                                </div>
                              )}
                              {step.receiver_email === party1_name && (
                                <div>
                                  You are receiver in this step. Once the paying party has verified your work and sent you the secret verification code, enter it below:
                                  <br /><br />
                                  Enter the Secret Verification Code:
                                  <input
                                    type="number"
                                    value={verificationCode}
                                    onChange={handleVerificationCodeChange}
                                  />
                                  {/*}
                                <button
                                  disabled={verificationCode != step.step_verification_code}
                                  onClick={withdraw}
                                >
                                  Withdraw {step.amount} ETH
                                </button>
                            {*/}
                                </div>
                              )}
                            </div>
                          </MKAlert>
                          ))
                        ) : (
                          <div>Loading steps...</div>
                        )}
                      </div>
                    </Grid>
                  ) : (
                    <Grid item xs={12} pr={1} mb={5}>
                      <h3>Contract Steps</h3>
                      <p style={{ color: "red" }}>After paying deposit, steps will be shown here!</p>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Card>
        </MKBox >

      </MKBox >
    </>
  );
}

export default DashboardPage;
