import Footer from "./sections/Footer";
import PropTypes from "prop-types";

// Import the necessary components here
import MKBox from "components/MKBox";
import ContractCondition from "./sections/Contact";

// Routes
//import routes from "routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/city-profile.jpg";

function SetCondition({ receivedContractData4th }) {
  return (
    <>
      <MKBox bgColor="white">
        <ContractCondition receivedContractData4th={receivedContractData4th} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>      
      </MKBox>
    </>
  );
}

SetCondition.propTypes = {
  receivedContractData4th: PropTypes.object,
  //setContractData: PropTypes.func.isRequired,
};

export default SetCondition;
