
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select, Button, Grid, TextField } from "@mui/material";

const NumbofParties = ({ contractData, setContractData }) => {
  const [partyTitles, setPartyTitles] = useState(["Importer", "Exporter", "Third Party"]); // Default titles
  const [partyInputs, setPartyInputs] = useState( ["", "", ""]); // Input fields for party names

  const handlePartyCountChange = (event) => {
    const count = parseInt(event.target.value);
    const updatedContractData = { ...contractData };
    updatedContractData.parties.numberOfParties = count;
    setContractData(updatedContractData);

    // Update partyTitles and partyInputs arrays
    if (count === 2) {
      setPartyTitles(["Importer", "Exporter"]);
      setPartyInputs(["", ""]);
    } else if (count === 3) {
      setPartyTitles(["Importer", "Exporter", "Third Party"]);
      setPartyInputs(["", "", ""]);
    }
  };

  const handlePartyInputChange = (event, index) => {
    const updatedInputs = [...partyInputs];
    updatedInputs[index] = event.target.value;
    setPartyInputs(updatedInputs);
  };

  const handleSubmit = () => {
    // You can access partyTitles and partyInputs arrays for further processing
    // For example, you can send this data to your contract creation logic.
    // console.log(partyTitles);
    // console.log(partyInputs);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Number of Parties</InputLabel>
          <Select
            value={contractData.parties.numberofparties}
            onChange={handlePartyCountChange}
            style={{ height: "3.2em" }}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

NumbofParties.propTypes = {
  contractData: PropTypes.object,
  setContractData: PropTypes.func,
};

export default NumbofParties;

