import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKPagination from "components/MKPagination";
import bgImage from "assets/images/examples/blog2.jpg";

function SecondPartyInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract contractData from location state
  const receivedContractData2nd = location.state.receivedContractData1st;

  // State variables for 2nd party information
  const [partyData, setPartyData] = useState(receivedContractData2nd.parties.partiesData[1]);

  useEffect(() => {
    // Log the contents of receivedContractData2nd when the component mounts
    console.log(receivedContractData2nd);
  }, [receivedContractData2nd]);

  const updateContractDataAfter2ndParty = () => {
    const updatedData = {
      ...receivedContractData2nd,
      parties: {
        ...receivedContractData2nd.parties,
        partiesData: [
          ...receivedContractData2nd.parties.partiesData.slice(0, 1), // Keep the other parties unchanged
          {
            ...receivedContractData2nd.parties.partiesData[1], // Keep other parties as they are
            ...partyData, // Update the first party data
          },
          ...receivedContractData2nd.parties.partiesData.slice(2), // Keep the other parties unchanged
        ],
      },
    };

    // Log the updated data
    console.log(updatedData);

    // Define the address based on the number of parties
    let nextPageAddress;
    if (receivedContractData2nd.parties.numberOfParties === 3) {
      nextPageAddress = "/services/smart-contract/set-3rd-party";
    } else {
      nextPageAddress = "/services/smart-contract/set-condition";
    }

    // Navigate to the next page and pass contractData as a prop
    navigate(nextPageAddress, {
      state: { receivedContractData2nd: updatedData },
    });
  };


  // Update contractData as the user enters data
  const handlePartyDataChange = (field, value) => {
    setPartyData({
      ...partyData,
      [field]: value,
    });
  };

  // Function to handle navigation to the next page
  const handleNextClick = () => {
    // Ensure contractData is updated before navigating
    updateContractDataAfter2ndParty();
  };

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the history stack
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 8 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Set Smart Contract Type-A
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Craft your customized Type-A smart contract effortlessly. Define parties,
                      cargo specifics, and confirmation protocols to streamline your secure
                      international trade agreements.
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      2/8. Parties Involved
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Details of the second party participating in the contract.
                    </MKTypography>
                    <Grid
                      container
                      item
                      xs={0}
                      md={0}
                      justifyContent="center"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKPagination size="medium">
                        <MKPagination item>1</MKPagination>
                        <MKPagination item active>2</MKPagination>
                        {receivedContractData2nd.parties.numberOfParties === 2 ? null : <MKPagination item>3</MKPagination>}
                      </MKPagination>

                    </Grid>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Exporter Name is"
                          placeholder="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required={true}
                          value={partyData.name}
                          onChange={(e) => handlePartyDataChange("name", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Exporter's Address is"
                          placeholder="Paris, France"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required={true}
                          multiline={true}
                          rows="2"
                          value={partyData.address}
                          onChange={(e) => handlePartyDataChange("address", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Exporter's Phone Number is"
                          placeholder="+33-..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="tel"
                          value={partyData.phoneNumber}
                          onChange={(e) => handlePartyDataChange("phoneNumber", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Exporter's Zip Code is"
                          placeholder="75020"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="number"
                          value={partyData.zipCode}
                          onChange={(e) => handlePartyDataChange("zipCode", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Exporter's Wallet Address is"
                          placeholder="X4kf5krs913dpf1f94fss"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required={true}
                          value={partyData.walletAddress}
                          onChange={(e) => handlePartyDataChange("walletAddress", e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        container
                        item
                        xs={10}
                        md={6}
                        justifyContent="flex-start"
                        textAlign="right"
                        ml="auto"
                      >
                        {/* Add a "Back" button to go to the previous page */}
                        <MKButton variant="outlined" color="info" onClick={handleGoBack}>
                          Back
                        </MKButton>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        {/* Add a "Next" button to go to the next page */}
                        <MKButton variant="gradient" color="info" onClick={handleNextClick}>
                          Next
                        </MKButton>
                      </Grid>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

SecondPartyInfo.propTypes = {
  receivedContractData2nd: PropTypes.object,
};

export default SecondPartyInfo;
