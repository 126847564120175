import FirstPartyInfo from "./sections/Contact";
import Footer from "./sections/Footer";
import PropTypes from "prop-types";

// Import the necessary components here
import MKBox from "components/MKBox";

// Routes
//import routes from "routes";

import DefaultFooter from "examples/Footers/DefaultFooter";

import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/city-profile.jpg";

function Set1stParty({ receivedContractData1st }) {
  return (
    <>
      <MKBox bgColor="white">
        <FirstPartyInfo receivedContractData1st={receivedContractData1st} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>      
      </MKBox>
    </>
  );
}

Set1stParty.propTypes = {
  receivedContractData1st: PropTypes.object,
  //setContractData: PropTypes.func.isRequired,
};

export default Set1stParty;
