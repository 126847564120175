import React, { useState } from 'react';
import { Link, useNavigate  } from 'react-router-dom'; // If you're using React Router for navigation
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'; // Assuming you're using Material-UI

const ShareContractPage = ({ contractData, partyNames }) => {
  const navigate = useNavigate ();
  const [shareMethod, setShareMethod] = useState('email');
  const [email, setEmail] = useState('');
  const [link, setLink] = useState(''); // Generate and store a unique link to share

  const handleShareMethodChange = (e) => {
    setShareMethod(e.target.value);
  };

  const handleSendEmail = () => {
    // Implement sending the contract to the specified email address
    // You can use a backend service to send the email
    // Include the contract details and link in the email
  };

  const handleGenerateLink = () => {
    // Generate a unique link to review and sign the contract
    // Store the link in the state, and you can also send it to the other parties
    // Typically, this link would route to the review and sign page for other parties
    const generatedLink = `/review-contract/${generateUniqueID()}`;
    setLink(generatedLink);
  };

  const generateUniqueID = () => {
    // Generate a unique ID (you may use a library for this)
    return Math.random().toString(36).substring(7);
  };

  const handleNext = () => {
    // Navigate to the page where other parties can review and sign the contract
    navigate(link);
  };

  return (
    <div>
      <h2>Share Contract</h2>
      <p>Choose how you want to share the contract with other parties:</p>

      <FormControl fullWidth>
        <InputLabel>Share Method</InputLabel>
        <Select
          value={shareMethod}
          onChange={handleShareMethodChange}
        >
          <MenuItem value="email">Send via Email</MenuItem>
          <MenuItem value="link">Generate a Link</MenuItem>
        </Select>
      </FormControl>

      {shareMethod === 'email' && (
        <div>
          <TextField
            label="Recipient's Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <Button variant="contained" onClick={handleSendEmail}>
            Send Email
          </Button>
        </div>
      )}

      {shareMethod === 'link' && (
        <div>
          <Button variant="contained" onClick={handleGenerateLink}>
            Generate Link
          </Button>
          <p>Generated Link: {link}</p>
          <Button variant="contained" onClick={handleNext}>
            Next: Allow Parties to Review and Sign
          </Button>
        </div>
      )}

      {/* Add a link to navigate back to the previous step (if needed) */}
      <Link to="/previous-step">Back to Previous Step</Link>
    </div>
  );
};

export default ShareContractPage;
