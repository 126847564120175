import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import PropTypes from "prop-types";

// Import the necessary components here
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
//import routes from "routes";
import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/city-profile.jpg";

function SetSubject({ contractData }) {
  return (
    <>
      <MKBox bgColor="white">
        <Contact contractData={contractData} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

SetSubject.propTypes = {
  contractData: PropTypes.object,
  //setContractData: PropTypes.func.isRequired,
};

export default SetSubject;
