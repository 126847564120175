/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

//Footer
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";


// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

//Connection to Backend
import { loginUser } from "../../../api/auth";

//show messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Party2SignIn() {


  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const handleLogin = async () => {
    try {
      const response = await loginUser(userData);
      // Handle the response, e.g., show a success message, redirect, etc.
      console.log('Login successful', response);
      // Logic after successful login where you receive the token
      // Store the token in local storage

      //delet for front// localStorage.setItem('userToken', response.token);

      //console.log("userToken",localStorage.getItem('userToken', response.token));
      // Redirect the user to the CoCampaignSettings page or wherever needed
      // For example, using React Router: history.push('/cocampaign-settings');
      /*new for front*/ localStorage.setItem('userToken', response.token);

      toast.success('Login successful!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        window.location.href = '/contract-initiate-party2'; // Redirect after successful login
      }, 3000);
    } catch (error) {
      console.error('Login error', error);
      if (error.response && error.response.status === 404) {
        toast.error('User not found!', {
          // Toast configuration for user not found error
        });
      } else if (error.response && error.response.status === 401) {
        toast.error('Invalid password!', {
          // Toast configuration for invalid password error
        });
      } else {
        toast.error('Login failed!', {
          // Toast configuration for other login errors
        });
      }
    }
  };


  const handleInputChange = (e) => {
    // Update the userData state when input fields change
    setUserData({ ...userData, [e.target.name]: e.target.value });
    localStorage.setItem('userEmail', userData.email)
  };

  // Get the URL parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Get the value of the 'contId' parameter from the URL
  const contId = urlParams.get('contId');

  // Check if 'contId' is present in the URL
  if (contId) {
    // Set 'contId' in the local storage as 'contract_id'
    localStorage.setItem('contractId', contId);
  }


  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <ToastContainer />
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Login
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput type="email" label="Email" fullWidth
                      name="email" // Add a name attribute for identification
                      onChange={handleInputChange} // Call handleInputChange on input change
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="password" label="Password" fullWidth
                      name="password" // Add a name attribute for identification
                      onChange={handleInputChange} // Call handleInputChange on input change
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth
                      onClick={handleLogin}
                    >
                      login
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/party2-register"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Register
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Party2SignIn;
