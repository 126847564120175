import axios from 'axios';
import { API_URL } from './constants';

export const fetchContracts = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard`, {
      headers: {
        'user-email': localStorage.getItem('userEmail'),
      },
    });

    if (response.status === 200) {
      return response.data.contracts;
    } else {
      throw new Error('Failed to fetch contracts');
    }
  } catch (error) {
    console.error('Error fetching contracts:', error);
    throw error;
  }
};
