// my-app/src/api/auth.js
import axios from 'axios';

import { API_URL } from './constants';

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-page`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-page`, userData);
    return response.data; //what's this???
  } catch (error) {
    throw error;
  }
};