/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Seamless Transaction Execution"
                    description="The automated cryptocurrency payment system ensures swift and efficient payment processing, eliminating the need for manual intervention, and thus reducing delays and errors."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Stable Coin Integration"
                    description="Leveraging stable coins like USDT, the system provides a secure and reliable medium for financial transactions, enhancing stability and trust in smart contract-based payments."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://scg.network/static/media/crypto-payment-card.b2edd736a7393bab28a5.jpg"
              title="Automated Payement"
              description="This innovative feature supports seamless cryptocurrency payments, particularly utilizing stable coins like USDT, offering security and efficiency in financial transactions."
              action={{
                type: "internal",
                route: "/services/smart-contract/select-contract",
                color: "info",
                label: "Use in Your Contract",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
