// frontend/src/api/cocampaignResultsApi.js
import axios from 'axios';

import { API_URL } from './constants';

// Function to update CoCampaignResults data
export const updateCoCampaignResults = (data, dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name, 
    dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
    dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
    dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson, 
    dispatchProductName,dispatchProducthsCode,dispatchPackageType, dispatchPackageAmount,
    dispatchStandardRequired, dispatchStandardList, dispatchStepsData) => {
    // Dispatch actions to update the state based on the provided data
    dispatchUserEmail(updateUserEmailAction(data));
    dispatchNumberOfParties(updateNumberOfPartiesAction(data));
    dispatchParty1Name(updateParty1NameAction(data));
    dispatchParty2Name(updateParty2NameAction(data));
    dispatchParty3Name(updateParty3NameAction(data));
    dispatchParty1AcceptanceStatus(updateParty1AcceptanceStatusAction(data));
    dispatchParty2AcceptanceStatus(updateParty2AcceptanceStatusAction(data));
    dispatchParty3AcceptanceStatus(updateParty3AcceptanceStatusAction(data));
    dispatchParty1DepositValue(updateParty1DepositValueAction(data));
    dispatchParty2DepositValue(updateParty2DepositValueAction(data));
    dispatchParty3DepositValue(updateParty3DepositValueAction(data));
    dispatchParty1DepositStatus(updateParty1DepositStatusAction(data));
    dispatchParty2DepositStatus(updateParty2DepositStatusAction(data));
    dispatchParty3DepositStatus(updateParty3DepositStatusAction(data));
    dispatchStepsPaymentsJson(updateStepsPaymentsJsonAction(data));
    dispatchProductName(updateProductNameAction(data));
    dispatchProducthsCode(updateProductHsCodeAction(data));
    dispatchPackageType(updatePackageTypeAction(data));
    dispatchPackageAmount(updatePackageAmountAction(data));
    dispatchStandardRequired(updateStandardRequiredAction(data));
    dispatchStandardList(updateStandardListAction(data));
    dispatchStepsData(updateStepsDataAction(data))
  };

// Example action creators for updating state
export const updateProductNameAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PRODUCT_NAME',
    payload: data.product_name, // Check the correct property name
});
export const updateProductHsCodeAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PRODUCT_HSCODE',
    payload: data.product_hsCode, // Check the correct property name
});
export const updatePackageTypeAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PACKAGE_TYPE',
    payload: data.package_type, // Check the correct property name
});
export const updatePackageAmountAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PACKAGE_AMOUNT',
    payload: data.package_amount, // Check the correct property name
});
export const updateStandardRequiredAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_STANDARD_REQUIRED',
    payload: data.standard_required, // Check the correct property name
});
export const updateStandardListAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_STANDARD_LIST',
    payload: data.standard_list, // Check the correct property name
});
export const updateStepsDataAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_STEPS_DATA',
    payload: data.steps_data, // Check the correct property name
});


export const updateUserEmailAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_USER_EMAIL',
    payload: data.user_email, // Check the correct property name
});

export const updateNumberOfPartiesAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_NUMBER_OF_PARTIES',
    payload: data.number_of_parties, // Check the correct property name
});

export const updateParty1NameAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_NAME',
    payload: data.party1_name, // Check the correct property name
});

export const updateParty2NameAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_NAME',
    payload: data.party2_name, // Check the correct property name
});

export const updateParty3NameAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_NAME',
    payload: data.party3_name, // Check the correct property name
});

export const updateParty1AcceptanceStatusAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_ACCEPTANCE_STATUS',
    payload: data.party1_acceptance_status, // Check the correct property name
});

export const updateParty2AcceptanceStatusAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_ACCEPTANCE_STATUS',
    payload: data.party2_acceptance_status, // Check the correct property name
});

export const updateParty3AcceptanceStatusAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_ACCEPTANCE_STATUS',
    payload: data.party3_acceptance_status, // Check the correct property name
});

export const updateParty1DepositValueAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_VALUE',
    payload: data.party1_deposit_value, // Check the correct property name
});

export const updateParty2DepositValueAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_VALUE',
    payload: data.party2_deposit_value, // Check the correct property name
});

export const updateParty3DepositValueAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_DEPOSIT_VALUE',
    payload: data.party3_deposit_value, // Check the correct property name
});

export const updateParty1DepositStatusAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY1_DEPOSIT_STATUS',
    payload: data.party1_deposit_status, // Check the correct property name
});

export const updateParty2DepositStatusAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY2_DEPOSIT_STATUS',
    payload: data.party2_deposit_status, // Check the correct property name
});

export const updateParty3DepositStatusAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_PARTY3_DEPOSIT_STATUS',
    payload: data.party3_deposit_status, // Check the correct property name
});

export const updateStepsPaymentsJsonAction = (data) => ({
    type: 'UPDATE_CO_CAMPAIGN_RESULTS_STEPS_PAYMENTS_JSON',
    payload: data.steps_payments_json, // Check the correct property name
});



export const fetchCoCampaignResults = async (contractId) => {
    try {
        // Include user email in the request headers
        const headers = { 'contract-id': contractId };

        const response = await axios.get(`${API_URL}/contract-initiate-party3`, { headers });
        console.log ('inside fetchCoCampaignResults, response.data', response.data.data )
        return response.data.data;
    } catch (error) {
        console.error('Error fetching CoCampaign results:', error);
        throw error;
    }
};

// Function to initialize CoCampaignResults data
export const initializeCoCampaignResults = async (contractId, dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name, 
    dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
    dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
    dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson, dispatchProductName,dispatchProducthsCode,dispatchPackageType, dispatchPackageAmount,
    dispatchStandardRequired, dispatchStandardList, dispatchStepsData) => {
    try {
        console.log("inside initializeCoCampaignResults api,contractId:",contractId)
        const initialData = await fetchCoCampaignResults(contractId);

        console.log('inside initializeCoCampaignResults, initialData:', initialData);
        console.log('inside initializeCoCampaignResults, user_email:', initialData.user_email);
        console.log('inside initializeCoCampaignResults, party2_name:', initialData.party2_name);


        // Call the function to handle the update
        updateCoCampaignResults(initialData, dispatchUserEmail, dispatchNumberOfParties, dispatchParty1Name, 
            dispatchParty2Name, dispatchParty3Name, dispatchParty1AcceptanceStatus, dispatchParty2AcceptanceStatus, dispatchParty3AcceptanceStatus,
            dispatchParty1DepositValue, dispatchParty2DepositValue, dispatchParty3DepositValue, dispatchParty1DepositStatus,
            dispatchParty2DepositStatus, dispatchParty3DepositStatus, dispatchStepsPaymentsJson, dispatchProductName,dispatchProducthsCode,dispatchPackageType, dispatchPackageAmount,
            dispatchStandardRequired, dispatchStandardList, dispatchStepsData);
    } catch (error) {
        console.error('Error initializing CoCampaign results:', error);
    }
};


export const updateDepositStatus = async (contId, depositStatus) => {
    try {
      // Make an API call to update the deposit status in the backend
      console.log("Request Payload:", { contId, depositStatus });
      const response = await axios.post(`${API_URL}/contract-initiate-party3`, {
        contId,
        depositStatus,
      });
  
      if (response.status === 200) {
        console.log('Deposit status updated successfully in the backend.');
      } else {
        console.error('Failed to update deposit status in the backend.');
      }
    } catch (error) {
      console.error('Error updating deposit status in the backend:', error);
    }
};
