import React from "react";
import PropTypes from "prop-types";
//import ReactDOM from 'react-dom';  
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from "@mui/material";

const ContractCosts = ({ receivedContractData4th, setReceivedContractData4th }) => {

  //const { mappedSteps }  = receivedContractData4th.steps;
  const { stepData } = receivedContractData4th?.steps || [];

  const addStep = () => {
    const newStep = {
      title: "",
      amount: "0",
      payer: "",
      receiver: "",
      settlementtime: 0,
      confirmationMethod: "UniqueCodes",//not applicable for this type of agreement
      PenaltyForEachDayOfDelay: 0,
      penaltyReceiver: "",
    };

    // Update contractData4th with the new step
    setReceivedContractData4th((prevData) => ({
      ...prevData,
      steps: {
        ...prevData.steps,
        stepData: [
          ...prevData.steps.stepData, newStep]
      }
    }));
  };
  /*
    const updatedData = {
      ...receivedContractData3rd,
      parties: {
        ...receivedContractData3rd.parties,
        partiesData: [
          ...receivedContractData3rd.parties.partiesData.slice(0, 2), // Keep the first two parties data unchanged
          {
            ...receivedContractData3rd.parties.partiesData[2], // Keep the third party as it is
            ...partyData, // Update the third party data
          },
        ],
      },
    };
  */
  const handleStepChange = (e, index, field) => {
    setReceivedContractData4th((prevData) => {
      const updatedSteps = [...prevData.steps.stepData]; // Access the array directly
      updatedSteps[index][field] = e.target.value;
      return {
        ...prevData,
        steps: {
          ...prevData.steps, // Keep other properties of 'steps'
          stepData: updatedSteps, // Update only the 'stepData' array
        },
      };
    });
  };

  return (
    <div>
      {stepData.length > 0 ? (
        stepData.map((step, index) => (
          <div key={index}>
            <h3 style={{ float: "left" }}>Step {index + 1}</h3>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Title of the Step"
                  value={step.title} // Update here
                  style={{ width: "13em", float: "left" }}
                  onChange={(e) => handleStepChange(e, index, "title")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Cost (in ETH)"
                  type="number"
                  value={step.amount} // Update here
                  style={{ width: "13.3em" }}
                  onChange={(e) => handleStepChange(e, index, "amount")}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: "13em", height: "2em", float: "left" }}>
                  <InputLabel>Payer</InputLabel>
                  <Select
                    value={step.payer} // Update here
                    style={{ height: "3em" }}
                    onChange={(e) => handleStepChange(e, index, "payer")}
                  >
                    <MenuItem value="Importer">Importer</MenuItem>
                    <MenuItem value="Exporter">Exporter</MenuItem>
                    <MenuItem value="ThirdParty">Third Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Receiver</InputLabel>
                  <Select
                    value={step.receiver} // Update here
                    style={{ height: "3em" }}
                    onChange={(e) => handleStepChange(e, index, "receiver")}
                  >
                    <MenuItem value="Importer">Importer</MenuItem>
                    <MenuItem value="Exporter">Exporter</MenuItem>
                    <MenuItem value="ThirdParty">Third Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Step Duration (In Days)"
                  type="number"
                  value={step.settlementtime} // Update here
                  style={{ width: "13.3em" }}
                  onChange={(e) => handleStepChange(e, index, "settlementtime")}
                />
              </Grid>
              {/*
              <Grid item xs={6}>
                <TextField
                  label="Penalty for each day of delay"
                  type="number"
                  value={step.PenaltyForEachDayOfDelay} // Update here
                  style={{ width: "13.3em" }}
                  onChange={(e) => handleStepChange(e, index, "PenaltyForEachDayOfDelay")}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Penalty Receiver</InputLabel>
                  <Select
                    value={step.penaltyReceiver} // Update here
                    style={{ height: "3em" }}
                    onChange={(e) => handleStepChange(e, index, "penaltyReceiver")}
                  >
                    <MenuItem value="Importer">Importer</MenuItem>
                    <MenuItem value="Exporter">Exporter</MenuItem>
                    <MenuItem value="ThirdParty">Third Party</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
        */}
              {/*         
              settlementtime: 0,
        //confirmationMethod: "UniqueCodes",
               PenaltyForEachDayOfDelay: 0,
        penaltyReceiver: "", */}
            </Grid>
          </div>
        ))
      ) : (
        <p>No steps available</p>
      )}
      < Button
        variant="contained"
        color="primary"
        onClick={addStep}
        style={{ margin: "1em", padding: "1em", float: "left" }}
      >
        Add Step
      </Button>
    </div >
  );
};

ContractCosts.propTypes = {
  receivedContractData4th: PropTypes.object,
  setReceivedContractData4th: PropTypes.func,
};

export default ContractCosts;
