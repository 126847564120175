import SecondPartyInfo from "./sections/Contact";
import Footer from "./sections/Footer";
import PropTypes from "prop-types";

// Import the necessary components here
import MKBox from "components/MKBox";

// Routes
//import routes from "routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/city-profile.jpg";

function Set2ndParty({ receivedContractData2nd }) {
  return (
    <>
      <MKBox bgColor="white">
        <SecondPartyInfo receivedContractData2nd={receivedContractData2nd} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>      
    </MKBox>
    </>
  );
}

Set2ndParty.propTypes = {
  receivedContractData2nd: PropTypes.object,
  //setContractData: PropTypes.func.isRequired,
};

export default Set2ndParty;
