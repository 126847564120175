import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select, TextField, Grid } from "@mui/material";

const ContractEntryPackage = ({ contractData, setContractData }) => {
  const [selectedUnit, setSelectedUnit] = useState(undefined ||"");
  const [amount, setAmount] = useState(undefined ||"");
  const unitOptions = [
    "Cartons",
    "Pallets",
    "Crates",
    "Pallet Boxes",
    "Corrugated Boxes",
    "Plastic Bags/Pouches",
    "Drums",
    "Bales",
    "Reels and Spools",
    "Blister Packs",
    "Totes and Bins",
    "Flexible Packaging",
    "Bulk Containers",
    "Glass Bottles",
    "Tins and Metal Cans",
    "Vacuum-Sealed Bags",
    "Wooden Boxes",
    "Foam Packaging",
    "Composite Packaging",
  ]; // List of units

  const handleUnitChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedUnit(selectedValue);
    const updatedContractData = { ...contractData, package: { ...contractData.package, type: selectedValue } };
    setContractData(updatedContractData);
  };

  const handleAmountChange = (event) => {
    const enteredAmount = event.target.value;
    setAmount(enteredAmount);
    const updatedContractData = { ...contractData, package: { ...contractData.package, amount: enteredAmount } };
    setContractData(updatedContractData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Select Packaging</InputLabel>
          <Select value={selectedUnit} onChange={handleUnitChange} style={{ height: "3.2em" }}>
            <MenuItem value="">
              <em>Select an option</em>
            </MenuItem>
            {unitOptions.map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Number of Packages"
          type="number"
          value={amount}
          onChange={handleAmountChange}
        />
      </Grid>
    </Grid>
  );
};

ContractEntryPackage.propTypes = {
  contractData: PropTypes.object,
  setContractData: PropTypes.func,
};

export default ContractEntryPackage;
